import * as React from 'react';
import { Accordion, AccordionItem, AccordionProvider, useAccordionProvider, useAccordionState } from '@szhsin/react-accordion';
import "./AccordionModule.css?__remix_sideEffect__";
import SwipeImage from '~/components/SwipeImage/SwipeImage.jsx';
import { useEffect, useState } from 'react';
import YouTubePlayer from 'react-player/youtube.js';
import { VideoMaybeBorder } from '~/legacy-components/index.js';
import VimeoPlayer from 'react-player/vimeo.js';
import AriaModal from 'react-aria-modal';
import { CSSTransition } from 'react-transition-group';
import closeIcon from '~/images/icon-close.svg';

const ItemHeader = ({ headline, index }) => {
  return (
    <div className="item-header">
      <h2 className="eyebrow">Concept {index + 1}</h2>
      <h3 className="item-headline widget-label">{headline}</h3>
      <div className="icon">
        <svg width="41" height="42" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M41 0.5H0V41.5H41V0.5ZM1.63965 39.86V2.14001H39.3596V39.86H1.63965Z" fill="black" />
          <path className='arrow-head' d="M13.7993 23.4114L12.5928 24.4661L20.5004 33L28.408 24.4661L27.2015 23.4114L20.5004 30.6433L13.7993 23.4114Z" fill="black" />
          <path className='arrow-body' d="M19.6885 31.8216H21.3117V8.17297H19.6885V31.8216Z" fill="black" />
        </svg>
      </div>
    </div>);

};

const ImageComponent = ({ image, isVisible }) => {
  if (image && image.asset) {
    return <SwipeImage asset={image.asset} isVisible={isVisible} />;
  }

  return null;
};

const PlayIcon = () => {
  return (
    <svg width="19" height="28" viewBox="0 0 19 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M18.4835 14.3742L0 0.847656V27.9008L18.4835 14.3742ZM13.5165 14.3742L2.66667 22.3143V6.43412L13.5165 14.3742Z" fill="white" />
    </svg>);

};

const VideoComponent = ({ video, hidden }) => {
  const [open, setOpen] = useState(false);

  if (!video || hidden) {
    return false;
  }

  const isYoutube = video.includes('youtu');

  const handlePlay = () => {
    setOpen(true);
  };

  const player = isYoutube ?
  <YouTubePlayer width='100%' height='auto' style={{ aspectRatio: '16/9' }} url={video} light={true} playIcon={<PlayIcon />} playing={true} /> :
  <VimeoPlayer width='100%' height='auto' style={{ aspectRatio: '16/9' }} url={video} light={true} playIcon={<PlayIcon />} playing={true} />;

  const modal = open ?
  <CSSTransition
    in={open}
    appear
    classNames="overlay overlay"
    key={'mobile-nav-overlay'}
    timeout={300}>

      <AriaModal
      titleText="Video Player"
      initialFocus="#close"
      getApplicationNode={() => document.getElementById('root')}
      underlayStyle={{ background: 'rgba(0,0,0,0.8)' }}
      underlayClass="mobile-nav--underlay"
      dialogStyle={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        textAlign: 'unset',
        paddingInline: '3rem'
      }}
      dialogClass="mobile-nav--dialog">

      <button
        id="close"
        className="btn--close icon"
        onClick={() => {
          setOpen(false);
        }}
        alt="close navigation button"
        style={{ width: '46px' }}>

        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.80548 8.5127L8.51259 7.80559L18.1945 17.4875L17.4874 18.1946L7.80548 8.5127Z" fill="white" />
          <path d="M17.4874 7.80554L18.1945 8.51265L8.51261 18.1946L7.8055 17.4875L17.4874 7.80554Z" fill="white" />
          <rect x="1" y="1" width="24" height="24" stroke="white" />
        </svg>
      </button>
      {player}
    </AriaModal>
  </CSSTransition> :

  false;

  return (
    <div className='video-container'>
      {player}
      <a className='video-cta eyebrow' href='#' onClick={handlePlay}>
        <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M1 1.16504V14.165L10 7.66504L1 1.16504Z" stroke="black" />
        </svg>
        See the video
      </a>
      {modal}
    </div>);

};


const AccordionModule = ({ entries }) => {

  const [activeIndex, setActiveIndex] = useState(0);

  const handleAccordionChange = ({ key, current }) => {
    switch (current.status) {
      case 'preEnter':
        setActiveIndex(key);
        break;
      case 'preExit':
        setActiveIndex(-1);
        break;
    }
  };

  useEffect(() => {
    if (!handleAccordionChange) return;

    handleAccordionChange({ key: 0, current: { status: 'preExit' } });
  });

  if (!entries) {
    console.error('Accordion component requires entries prop');
    return <div />;
  }

  entries = Array.isArray(entries) ? entries : [entries];

  return (
    <Accordion className='Accordion' transition transitionTimeout={250} onStateChange={handleAccordionChange}>
      {entries.map((entry, index) => {
        return (
          <AccordionItem className='AccordionItem'
          key={index}
          itemKey={index}
          header={<ItemHeader headline={entry.headline} index={index} />}>
            <div className='body'>
              <div dangerouslySetInnerHTML={{ __html: entry.conceptsWysiwyg }}></div>
              <ImageComponent image={entry.image} isVisible={activeIndex === index} />
              <VideoComponent hidden={entry.image && entry.image.asset} video={entry.youtubeId} />
            </div>
          </AccordionItem>);

      })}
    </Accordion>);

};
export default AccordionModule;