import * as React from 'react';
import "./SwipeImage.css?__remix_sideEffect__";
import { useEffect, useRef, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

const isScrollable = (element) => {
  return element.scrollWidth > element.clientWidth;
};

const PanButton = ({ direction, isDisabled, isHidden, onClick }) => {
  return (
    <button className={`pan-button ${direction}`} disabled={isDisabled} style={{ display: isHidden ? 'none' : 'inline-flex' }} onClick={onClick}>
      <svg width="41" height="42" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M41 0.847656H0V41.8477H41V0.847656ZM1.63965 40.2077V2.48767H39.3596V40.2077H1.63965Z" fill="black" />
        <path d="M23.3253 27.6353L24.38 28.8418L32.9139 20.9342L24.38 13.0266L23.3253 14.2331L30.5572 20.9342L23.3253 27.6353Z" fill="black" />
        <path d="M31.7356 21.7461L31.7356 20.1229L8.08691 20.1229L8.08691 21.7461L31.7356 21.7461Z" fill="black" />
      </svg>
    </button>);

};

const SwipeImage = ({ asset, isVisible }) => {
  const swipeImageRef = useRef();
  const [isOverflowing, setOverflowing] = useState(false);
  const [container, setContainer] = useState(null);
  const [nexDisabled, setNextDisabled] = useState(false);
  const [prevDisabled, setPrevDisabled] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);

  useResizeObserver(swipeImageRef, (entry) => {
    if (!container) {
      return;
    }

    setOverflowing(isScrollable(container));
    setScrollPosition(0);
    container.scrollTo({ left: 0 });
  });

  useEffect(() => {
    const container = swipeImageRef.current.querySelector('.image-container');
    setContainer(container);
    setOverflowing(isScrollable(container));
  }, [isVisible]);

  useEffect(() => {
    if (!container) {
      return;
    }

    setNextDisabled(scrollPosition >= container.scrollWidth - container.offsetWidth);
    setPrevDisabled(scrollPosition <= 0);
  }, [scrollPosition]);

  useEffect(() => {
    if (!container) {
      return;
    }

    container.addEventListener('touchmove', (e) => {
      e.preventDefault();
      return false;
    });
    container.addEventListener('mousewheel', (e) => {
      e.preventDefault();
      return false;
    });
  }, [container]);

  if (!asset) {
    console.error('SwipeImage component requires asset prop');
    return <div />;
  }

  const onPrevClick = () => {
    if (!container) {
      return;
    }

    container.scrollBy({ left: -container.clientWidth, behavior: 'smooth' });
    setScrollPosition(scrollPosition - container.clientWidth);
  };

  const onNextClick = () => {
    if (!container) {
      return;
    }

    container.scrollBy({ left: container.clientWidth, behavior: 'smooth' });
    setScrollPosition(scrollPosition + container.clientWidth);
  };

  return (
    <div className="SwipeImage" ref={swipeImageRef}>
      <div className="image-container"
      dangerouslySetInnerHTML={{ __html: asset }} />
      <div className="controls">
        <PanButton direction="prev" isDisabled={prevDisabled} isHidden={!isOverflowing} onClick={onPrevClick} />
        <PanButton direction="next" isDisabled={nexDisabled} isHidden={!isOverflowing} onClick={onNextClick} />
      </div>
    </div>);

};
export default SwipeImage;